import {Button, Card, Flex, Tabs, DatePicker, InputNumber, Progress, Space} from "antd";
import {useEffect, useState} from "react";
import {requestFn} from "../helpers";
import {Link} from "react-router-dom";
import dayjs from 'dayjs';
import {
    BarChart,
    Bar, XAxis,
    YAxis, CartesianGrid,
    Tooltip, Legend,
    ResponsiveContainer,
    Cell
} from 'recharts';
import {
    simplifyData,
    transformByBatchNumber,
    transformByOwnerAlias,
    trasformedByStepName,
    trasformedByStepNameAndAffector,
} from "../statTransformers";

const {RangePicker} = DatePicker;

function formatTime(milliseconds) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutes}:${formattedSeconds}`;
}


function formatDateWithoutYear(timestamp) {
    const date = new Date(timestamp);
    const month = date.toLocaleString('cs-CS');
    return month.replace(/202\d/g, '').split(':').slice(0, -1).join(':');
}


const average = (arr) => Math.round(arr.reduce((a, b) => a + b, 0) / arr.length);

const CustomizedTick = (props) => {
    const {x, y, payload} = props;
    return (
      <g transform={`translate(${x},${y})`}>
          <text x={0} y={0} dy={16} textAnchor="end" fill="#fff" transform="rotate(-35)">
              {payload.value}
          </text>
      </g>
    );
}

export const Stats = ({token}) => {
    const [affector, setAffector] = useState('batchNumber')
    const [date, setDate] = useState(() => {
        const now = dayjs();
        return [now.startOf('day'), now.endOf('day')];
    })
    const [error, setError] = useState('')
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState('general')
    const [threshold, setThreshold] = useState(0.95)
    const [hideHighProbabilities, setHideHighProbabilities] = useState(false)
    const [selectedBatches, setSelectedBatches] = useState([])

    const isToday = date && date[0] && date[1] && 
        date[0].isSame(dayjs().startOf('day')) && 
        date[1].isSame(dayjs().endOf('day'));

    const isThisWeek = date && date[0] && date[1] && 
        date[0].isSame(dayjs().startOf('week')) && 
        date[1].isSame(dayjs().endOf('week'));

    const isThisMonth = date && date[0] && date[1] && 
        date[0].isSame(dayjs().startOf('month')) && 
        date[1].isSame(dayjs().endOf('month'));

    const exportToCSV = () => {
        // Prepare CSV headers
        const headers = [
            'Timestamp',
            'Owner',
            'SUID',
            'Batch #',
            'Test Status',
            'Left Channel',
            'Left Probability',
            'Right Channel',
            'Right Probability',
            'Status'
        ].join(',');

        // Format data rows
        const rows = audioTestTableData.map(item => {
            const passes = item.left.probability >= threshold && item.right.probability >= threshold;
            return [
                formatDateWithoutYear(item.timestamp),
                item.ownerAlias,
                item.suid,
                item.batchNumber,
                item.testStatus,
                item.left.className,
                (item.left.probability * 100).toFixed(2) + '%',
                item.right.className,
                (item.right.probability * 100).toFixed(2) + '%',
                passes ? 'PASS' : 'FAIL'
            ].join(',');
        }).join('\n');

        // Combine headers and rows
        const csvContent = `${headers}\n${rows}`;
        
        // Create blob and download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `audio_test_results_${dayjs().format('YYYY-MM-DD_HH-mm')}.csv`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const simplifiedData = simplifyData(data)

    // Get unique batch numbers from the data
    const uniqueBatchNumbers = [...new Set(simplifiedData.map(item => 
        item.batchNumber
    ).filter(Boolean))].sort((a, b) => a - b)

    const toggleBatchFilter = (batch) => {
        setSelectedBatches(prev => {
            if (prev.includes(batch)) {
                return prev.filter(b => b !== batch);
            }
            return [...prev, batch];
        });
    };

    // Filter data based on selected batches
    const filteredData = selectedBatches.length > 0
        ? simplifiedData.filter(item => selectedBatches.includes(item.batchNumber))
        : simplifiedData;

    const getData = async () => {
        !loading && setLoading(true)
        let path = `/events?type=test`
        let requestDate = date ? `&from=${date[0].toISOString()}&to=${date[1].toISOString()}` : ''
        const finalPath = `${path}${requestDate}`
        await requestFn('GET', finalPath, token, setData, setError)
        setLoading(false)
    }

    const affectorTabList = [
        {
            key: 'batchNumber',
            label: 'By batch #',
        },
        {
            key: 'ownerAlias',
            label: 'By barel',
        },
    ]

    useEffect(() => {
        getData()
    }, [date]);

    const testWithTime = data.filter((item) => item.updateData?.newTestData?.additionalData?.testTime)
    const testTimes = testWithTime.map((item) => item.updateData.newTestData.additionalData.testTime);

    // console.log(data)

    /*
[
    {
        "test_name": "FIRMWARE & SUID",
        "test_command": "FW_TEST",
        "test_description": "Check if firmware is up to date and set SUID",
        "test_status": "SUCCESS",
        "test_version": 1,
        "test_steps": [
            {
                "test_step_name": "Firmware update",
                "test_step_description": "Check if firmware is up to date and update if needed",
                "test_step_command": "FW_UPDATE",
                "test_step_retries": 0,
                "test_step_timetout_seconds": 200,
                "test_step_status": "SUCCESS",
                "test_step_value": "FW update: not_needed0.51"
            },
            {
                "test_step_name": "SUID & HW",
                "test_step_description": "Set SUID and HW version",
                "test_step_command": "GET_SET_SUID",
                "test_step_retries": 0,
                "test_step_timetout_seconds": 30,
                "test_step_status": "FAIL",
                "test_step_value": "SUID SASLE0M & hw version 5.0 set"
            }
        ]
    },
     */

    // console.log(simplifiedData)
    const byStepName = trasformedByStepName(filteredData)

    const byStepNameAndOwner = trasformedByStepNameAndAffector(filteredData, 'ownerAlias')
    const byStepNameAndBatch = trasformedByStepNameAndAffector(filteredData, 'batchNumber')

    const decideData = () => {
        if (affector === 'batchNumber') {
            return byStepNameAndBatch
        }
        if (affector === 'ownerAlias') {
            return byStepNameAndOwner
        }
    }

    const barData = decideData().map((item) => {
        return Object.entries(item).map(([key, value]) => ({
                dataKey: key,
                stackId: key.split('_')[0]
        }))
    }).flat()
    const uniqueBarData = barData.filter((item, index, self) => {
          return !['undefined', 'label'].includes(item.stackId) && index === self.findIndex((t) => (
            t.dataKey === item.dataKey && t.stackId === item.stackId
          ));
      }
    )
    const chartData = decideData() || []

    const byBatchNumber = transformByBatchNumber(simplifiedData)

    const byOwnerAlias = transformByOwnerAlias(simplifiedData)

    const simplifiedDataWithoutTests = filteredData.map((item) => {
        let newData = {...item};
        delete newData.testsData;
        return newData;
    })

    const audioTestTableData = filteredData
        .filter(item => item.audioTest)
        .map(item => ({
            timestamp: item.timestamp,
            ownerAlias: item.ownerAlias,
            suid: item.suid,
            batchNumber: item.batchNumber,
            result: item.result,
            left: {
                className: item.audioTest.left[0].className,
                probability: item.audioTest.left[0].probability
            },
            right: {
                className: item.audioTest.right[0].className,
                probability: item.audioTest.right[0].probability
            },
            testStatus: item.testsData?.find(test => test.test_name === 'Audio Test')?.test_status || 'N/A'
        }));

    // Calculate pass rates for different thresholds
    const generateDistributionData = () => {
        const thresholds = Array.from({ length: 21 }, (_, i) => i * 0.05); // 0 to 1 in steps of 0.05
        return thresholds.map(threshold => {
            const passedDevices = audioTestTableData.filter(item => 
                item.left.probability >= threshold && 
                item.right.probability >= threshold
            );
            return {
                threshold: (threshold * 100).toFixed(0) + '%',
                passRate: (passedDevices.length / audioTestTableData.length * 100).toFixed(1),
                devices: passedDevices.length
            };
        });
    };

    // Generate probability occurrence data
    const generateProbabilityDistribution = () => {
        const thresholds = Array.from({ length: 21 }, (_, i) => i * 0.05);
        const distribution = thresholds.map(threshold => ({
            probability: (threshold * 100).toFixed(0) + '%',
            count: 0
        }));

        audioTestTableData.forEach(item => {
            const leftIndex = Math.floor(item.left.probability / 0.05);
            const rightIndex = Math.floor(item.right.probability / 0.05);
            
            if (leftIndex >= 0 && leftIndex < distribution.length) {
                distribution[leftIndex].count++;
            }
            if (rightIndex >= 0 && rightIndex < distribution.length) {
                distribution[rightIndex].count++;
            }
        });

        // Filter out high probabilities if hideHighProbabilities is true
        return hideHighProbabilities 
            ? distribution.filter(d => parseFloat(d.probability) < 90)
            : distribution;
    };

    const distributionData = generateDistributionData();
    const probabilityDistribution = generateProbabilityDistribution();

    // Calculate pass rates based on threshold
    const calculatePassRates = (threshold) => {
        if (!audioTestTableData.length) return { current: 0, total: 0, percentage: 0 };
        
        const passedDevices = audioTestTableData.filter(item => 
            item.left.probability >= threshold && 
            item.right.probability >= threshold
        );
        
        return {
            current: passedDevices.length,
            total: audioTestTableData.length,
            percentage: (passedDevices.length / audioTestTableData.length) * 100
        };
    };

    const passRates = calculatePassRates(threshold);

    const tabItems = [
        {
            key: 'general',
            label: 'General Tests',
            children: (
                <div>
                    <table>
                        <thead>
                        <tr>
                            {Object.keys(simplifiedDataWithoutTests[0] || {}).map((item) => (
                              <th key={item}>{item}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {simplifiedDataWithoutTests.map((item) => (
                          <tr key={item.timestamp}>
                              <td>{formatDateWithoutYear(item.timestamp)}</td>
                              <td>{item.ownerAlias}</td>
                              <td><Link to={`/device/${item?.suid}`}>{item?.suid}</Link></td>
                              <td>{item.result}</td>
                              <td>{item.batchNumber}</td>
                              <td>{item.testTime && formatTime(item.testTime)}</td>
                          </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )
        },
        {
            key: 'audio',
            label: 'Audio Tests',
            children: (
                <div>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <div style={{ height: 300 }}>
                            <Flex justify="end" style={{ marginBottom: 8 }}>
                                <Button
                                    size="small"
                                    onClick={() => setHideHighProbabilities(!hideHighProbabilities)}
                                >
                                    {hideHighProbabilities ? "Show" : "Hide"} 90-100% Range
                                </Button>
                            </Flex>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    data={probabilityDistribution}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 40,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis 
                                        dataKey="probability"
                                        label={{ 
                                            value: 'Probability Range', 
                                            position: 'bottom',
                                            offset: 0,
                                            style: { fill: '#fff' }
                                        }}
                                        tick={{ fill: '#fff' }}
                                    />
                                    <YAxis
                                        label={{ 
                                            value: 'Number of Occurrences (Both Channels)', 
                                            angle: -90, 
                                            position: 'insideLeft',
                                            style: { fill: '#fff' }
                                        }}
                                        tick={{ fill: '#fff' }}
                                    />
                                    <Tooltip 
                                        formatter={(value) => [
                                            `${value} occurrences`,
                                            'Probability Count'
                                        ]}
                                    />
                                    <Bar 
                                        dataKey="count" 
                                        fill="#82ca9d" 
                                        name="Probability Count"
                                    >
                                        {probabilityDistribution.map((entry, index) => (
                                            <Cell 
                                                key={`cell-${index}`}
                                                fill={entry.probability === (threshold * 100).toFixed(0) + '%' ? '#ffa500' : '#82ca9d'}
                                            />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <div style={{ height: 300 }}>
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart
                                    data={distributionData}
                                    margin={{
                                        top: 20,
                                        right: 30,
                                        left: 20,
                                        bottom: 40,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis 
                                        dataKey="threshold"
                                        label={{ 
                                            value: 'Probability Threshold', 
                                            position: 'bottom',
                                            offset: 0,
                                            style: { fill: '#fff' }
                                        }}
                                        tick={{ fill: '#fff' }}
                                    />
                                    <YAxis
                                        label={{ 
                                            value: 'Pass Rate (%)', 
                                            angle: -90, 
                                            position: 'insideLeft',
                                            style: { fill: '#fff' }
                                        }}
                                        tick={{ fill: '#fff' }}
                                    />
                                    <Tooltip 
                                        formatter={(value, name) => [
                                            `${value}% (${distributionData[distributionData.findIndex(d => d.passRate === value)].devices} devices)`,
                                            'Pass Rate'
                                        ]}
                                    />
                                    <Bar 
                                        dataKey="passRate" 
                                        fill="#82ca9d"
                                        name="Pass Rate"
                                    >
                                        {distributionData.map((entry, index) => (
                                            <Cell 
                                                key={`cell-${index}`}
                                                fill={entry.threshold === (threshold * 100).toFixed(0) + '%' ? '#ffa500' : '#82ca9d'}
                                            />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <Flex align="center" gap="middle">
                            <div style={{ minWidth: 200 }}>
                                Probability Threshold:
                                <InputNumber
                                    min={0}
                                    max={1}
                                    step={0.01}
                                    value={threshold}
                                    onChange={setThreshold}
                                    style={{ marginLeft: 8 }}
                                />
                            </div>
                            <Progress
                                percent={passRates.percentage.toFixed(1)}
                                format={(percent) => 
                                    `${passRates.current}/${passRates.total} devices (${percent}%) pass at ${(threshold * 100).toFixed(0)}% threshold`
                                }
                                status={passRates.percentage > 90 ? "success" : "normal"}
                                style={{ width: '25%' }}
                            />
                        </Flex>
                    </Space>
                    <table>
                        <thead>
                        <tr>
                            <th>Timestamp</th>
                            <th>Owner</th>
                            <th>SUID</th>
                            <th>Batch #</th>
                            <th>Test Status</th>
                            <th>Left Channel</th>
                            <th>Left Probability</th>
                            <th>Right Channel</th>
                            <th>Right Probability</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {audioTestTableData.map((item) => {
                            const passes = item.left.probability >= threshold && item.right.probability >= threshold;
                            return (
                                <tr key={item.timestamp} style={{ 
                                    backgroundColor: passes ? 'rgba(130, 202, 157, 0.1)' : 'rgba(236, 112, 99, 0.1)'
                                }}>
                                    <td>{formatDateWithoutYear(item.timestamp)}</td>
                                    <td>{item.ownerAlias}</td>
                                    <td><Link to={`/device/${item?.suid}`}>{item?.suid}</Link></td>
                                    <td>{item.batchNumber}</td>
                                    <td style={{
                                        color: item.testStatus === 'SUCCESS' ? '#82ca9d' : '#ec7063'
                                    }}>{item.testStatus}</td>
                                    <td>{item.left.className}</td>
                                    <td style={{ 
                                        color: item.left.probability >= threshold ? '#82ca9d' : '#ec7063'
                                    }}>{(item.left.probability * 100).toFixed(2)}%</td>
                                    <td>{item.right.className}</td>
                                    <td style={{ 
                                        color: item.right.probability >= threshold ? '#82ca9d' : '#ec7063'
                                    }}>{(item.right.probability * 100).toFixed(2)}%</td>
                                    <td style={{ 
                                        color: passes ? '#82ca9d' : '#ec7063',
                                        fontWeight: 'bold'
                                    }}>{passes ? 'PASS' : 'FAIL'}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </div>
            )
        }
    ];

    return (
        <div style={{
            padding: 24,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        }}>
            <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: 8}}>
                <Space direction="vertical" size="small">
                    <Space style={{width: '100%', justifyContent: 'space-between'}}>
                        <Space direction="vertical" size="small">
                            <RangePicker
                              showTime
                              onChange={(date) => {
                                  setDate(date);
                              }}
                              format="YYYY-MM-DD HH:mm:ss"
                              size='small'
                              value={date}
                            />
                            <Space>
                                <Button 
                                    size="small"
                                    type={isToday ? "primary" : "default"}
                                    onClick={() => {
                                        const now = dayjs();
                                        const startOfDay = now.startOf('day');
                                        const endOfDay = now.endOf('day');
                                        setDate([startOfDay, endOfDay]);
                                    }}
                                >
                                    Today
                                </Button>
                                <Button 
                                    size="small"
                                    type={isThisWeek ? "primary" : "default"}
                                    onClick={() => {
                                        const now = dayjs();
                                        const startOfWeek = now.startOf('week');
                                        const endOfWeek = now.endOf('week');
                                        setDate([startOfWeek, endOfWeek]);
                                    }}
                                >
                                    This Week
                                </Button>
                                <Button 
                                    size="small"
                                    type={isThisMonth ? "primary" : "default"}
                                    onClick={() => {
                                        const now = dayjs();
                                        const startOfMonth = now.startOf('month');
                                        const endOfMonth = now.endOf('month');
                                        setDate([startOfMonth, endOfMonth]);
                                    }}
                                >
                                    This Month
                                </Button>
                            </Space>
                            {date && (
                                <Space direction="vertical" size={0}>
                                    <div style={{ fontSize: '14px', color: '#888' }}>
                                        {`${data.length} devices in selected period (${dayjs(date[0]).format('DD.MM.YYYY HH:mm')} - ${dayjs(date[1]).format('DD.MM.YYYY HH:mm')})`}
                                    </div>
                                    <div style={{ fontSize: '14px', color: '#888' }}>
                                        {`Test time average${date ? ' in selected period' : ''}: ${loading && !data.length ? 'Loading...' : formatTime(average(testTimes))}`}
                                    </div>
                                </Space>
                            )}
                        </Space>
                        <Space>
                            <Button loading={loading} onClick={getData}>REFETCH</Button>
                            <Button onClick={exportToCSV}>EXPORT TO CSV</Button>
                        </Space>
                    </Space>
                </Space>
                <Space direction="vertical" size="small">
                    <Tabs
                      style={{width: 300}}
                      activeKey={affector}
                      onChange={(key) => {
                          setAffector(key);
                          setSelectedBatches([]);
                      }}
                      items={affectorTabList}
                    />
                    {affector === 'batchNumber' && uniqueBatchNumbers.length > 0 && (
                        <Space wrap>
                            {uniqueBatchNumbers.map(batch => (
                                <Button
                                    key={batch}
                                    size="small"
                                    type={selectedBatches.includes(batch) ? "primary" : "default"}
                                    onClick={() => toggleBatchFilter(batch)}
                                >
                                    {`Batch #${batch}`}
                                </Button>
                            ))}
                            {selectedBatches.length > 0 && (
                                <Button
                                    size="small"
                                    onClick={() => setSelectedBatches([])}
                                    type="link"
                                >
                                    Clear filters
                                </Button>
                            )}
                        </Space>
                    )}
                </Space>
            </div>
            <div style={{minHeight: 500, display: "flex"}}>
                <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                      height={300}
                      width={1100}
                      data={chartData}
                      margin={{
                          top: 20,
                          right: 30,
                          bottom: 80,
                      }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="label"
                          minTickGap={-75}
                          tick={<CustomizedTick/>}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top'/>
                        {uniqueBarData.map((item) => (
                          <Bar
                            key={item.dataKey}
                            dataKey={item.dataKey}
                            stackId={item.stackId}
                            fill={item.dataKey.includes('SUCCESS') ? "#82ca9d" : item.dataKey.includes('NOT_STARTED') ? "gray" : "#ec7063"}
                          />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div style={{minHeight: 500, display: "flex"}}>
                <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                      height={300}
                      width={1100}
                      data={byStepName}
                      margin={{
                          top: 20,
                          right: 30,
                          bottom: 80,
                      }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="label"
                          minTickGap={-75}
                          tick={<CustomizedTick/>}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top'/>
                        <Bar dataKey="SUCCESS" stackId="a" fill="#82ca9d" />
                        <Bar dataKey="FAILED" stackId="a" fill="#ec7063" />
                        <Bar dataKey="NOT_STARTED" stackId="a" fill="gray" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <div style={{minHeight: 500, display: "flex"}}>
                <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                      height={300}
                      width={1100}
                      data={byOwnerAlias}
                      margin={{
                          top: 20,
                          right: 30,
                          bottom: 60,
                      }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="label"
                          minTickGap={-50}
                          tick={<CustomizedTick/>}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top'/>
                        <Bar dataKey="SUCCESS" stackId="a" fill="#82ca9d" />
                        <Bar dataKey="FAILED" stackId="a" fill="#ec7063" />
                    </BarChart>
                </ResponsiveContainer>
                <ResponsiveContainer width='100%' height='100%'>
                    <BarChart
                      height={300}
                      width={1100}
                      data={byBatchNumber}
                      margin={{
                          top: 20,
                          right: 30,
                          bottom: 60,
                      }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="label"
                          minTickGap={-50}
                          tick={<CustomizedTick/>}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend verticalAlign='top'/>
                        <Bar dataKey="SUCCESS" stackId="a" fill="#82ca9d" />
                        <Bar dataKey="FAILED" stackId="a" fill="#ec7063" />
                    </BarChart>
                </ResponsiveContainer>
            </div>
            <Card style={{ width: '100%' }}>
                <Flex vertical>
                    <Tabs
                        activeKey={activeTab}
                        onChange={setActiveTab}
                        items={tabItems}
                    />
                </Flex>
            </Card>
        </div>
    )
}
