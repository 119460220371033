const trasformedByStepName = (data) => data.reduce((acc, testGroup) => {
  testGroup.testsData?.forEach(test => {
    test.test_steps.forEach(step => {
      const correctedStepName = step.test_step_name === 'CONNECT_BLCLASSIC_STEP' ? 'Connect BL Classic' : step.test_step_name;
      const stepNameIndex = acc.findIndex((item) => item.label === correctedStepName);
      if (stepNameIndex === -1) {
        acc.push({
          label: step.test_step_name,
        })
      }
      const stepNameIndex2 = acc.findIndex((item) => item.label === correctedStepName);
      const desiredStep = acc[stepNameIndex2];
      if (desiredStep && !desiredStep[step.test_step_status]) {
        acc[stepNameIndex2] = {
          ...desiredStep,
          [step.test_step_status]: 1,
        }
        return acc
      }
      acc[stepNameIndex2] = {
        ...desiredStep,
        [step.test_step_status]: desiredStep[step.test_step_status] + 1,
      }
    });
  });
  return acc;
}, []);

const trasformedByStepNameAndAffector = (data, affector) => data.reduce((acc, testGroup, rindex) => {
  const affectorKey = testGroup[affector];
  testGroup.testsData?.forEach((test, index) => {
    test.test_steps.forEach((step, sindex) => {
      const correctedStepName = step.test_step_name === 'CONNECT_BLCLASSIC_STEP' ? 'Connect BL Classic' : step.test_step_name;
      const stepNameIndex = acc.findIndex((item) => item.label === correctedStepName);
      if (stepNameIndex === -1) {
        acc.push({
          label: step.test_step_name,
        })
      }
      const stepNameIndex2 = acc.findIndex((item) => item.label === correctedStepName);
      const desiredStep = acc[stepNameIndex2];
      const desiredOwnerState = affectorKey + '_' + step.test_step_status
      if (!desiredStep[desiredOwnerState]) {
        acc[stepNameIndex2][desiredOwnerState] = 1
      }
      acc[stepNameIndex2][desiredOwnerState] = acc[stepNameIndex2][desiredOwnerState] + 1
    });
  });
  return acc;
}, []);

const trasformedByStepNameAndOwner = (data) => data.reduce((acc, testGroup, rindex) => {
  const ownerAlias = testGroup.ownerAlias;
  testGroup.testsData?.forEach((test, index) => {
    test.test_steps.forEach((step, sindex) => {
      const correctedStepName = step.test_step_name === 'CONNECT_BLCLASSIC_STEP' ? 'Connect BL Classic' : step.test_step_name;
      const stepNameIndex = acc.findIndex((item) => item.label === correctedStepName);
      if (stepNameIndex === -1) {
        acc.push({
          label: step.test_step_name,
        })
      }
      const stepNameIndex2 = acc.findIndex((item) => item.label === correctedStepName);
      const desiredStep = acc[stepNameIndex2];
      const desiredOwnerState = ownerAlias + '_' + step.test_step_status
      if (!desiredStep[desiredOwnerState]) {
        acc[stepNameIndex2][desiredOwnerState] = 1
      }
      acc[stepNameIndex2][desiredOwnerState] = acc[stepNameIndex2][desiredOwnerState] + 1
    });
  });
  return acc;
}, []);

const transformByBatchNumber = (data) => data.reduce((acc, testGroup) => {
  const batchNumber = testGroup.batchNumber;
  const batchIndex = acc.findIndex((item) => item.label === (batchNumber || 'NO_BATCH#'));
  if (batchIndex === -1) {
    acc.push({
      label: batchNumber || 'NO_BATCH#',
    });
  }
  const batchIndex2 = acc.findIndex((item) => item.label === (batchNumber || 'NO_BATCH#'));
  const desiredBatch = acc[batchIndex2];
  if (!desiredBatch[testGroup.result]) {
    acc[batchIndex] = {
      ...desiredBatch,
      [testGroup.result]: 1,
    }
    return acc;
  }
  acc[batchIndex2] = {
    ...desiredBatch,
    [testGroup.result]: desiredBatch[testGroup.result] + 1,
  }
  return acc;
}, [])

const transformByOwnerAlias = (data) => data.reduce((acc, testGroup) => {
  const ownerAlias = testGroup.ownerAlias;
  const ownerIndex = acc.findIndex((item) => item.label === ownerAlias);
  if (ownerIndex === -1) {
    acc.push({
      label: ownerAlias,
    });
  }
  const ownerIndex2 = acc.findIndex((item) => item.label === ownerAlias);
  const desiredOwner = acc[ownerIndex2];
  if (!desiredOwner[testGroup.result]) {
    acc[ownerIndex2] = {
      ...desiredOwner,
      [testGroup.result]: 1,
    }
    return acc;
  }
  acc[ownerIndex2] = {
    ...desiredOwner,
    [testGroup.result]: desiredOwner[testGroup.result] + 1,
  }
  return acc;
}, [])

const simplifyData = (data) => data.map((item) => {
  const newTestData = item.updateData?.newTestData;
  const predictions = newTestData?.additionalData?.audio?.predictions;
  const audioSeparated = predictions ? Object.entries(predictions)?.map(([key, value]) => {
    const good = value?.find((item) => item.className === 'good')?.probability
    return ({
      "test_step_name": 'Audio_' + key,
      "test_step_status": good > 0.95? 'SUCCESS' : 'FAILED',
      "test_step_value": value.map((item) => ({[item.className]: item.probability})),
    });
  }) : []

  const testData = Array.isArray(newTestData?.testData) ? newTestData.testData : [];
  const extendedAudioTestData = testData.map((item) => {
    if (item.test_name === 'Audio Test') {
      return {
        ...item,
        test_steps: item.test_steps.concat(audioSeparated)
      }
    }
    return item
  });

  return ({
    testTime: newTestData?.additionalData?.testTime,
    ownerAlias: newTestData?.ownerAlias,
    suid: item.suid,
    testsData: extendedAudioTestData,
    audioTest: newTestData?.additionalData?.audio?.predictions,
    result: testData.every((item) => item.test_status === 'SUCCESS') ? 'SUCCESS' : 'FAILED',
    timestamp: newTestData?.timestamp,
    batchNumber: newTestData?.additionalData?.batchNumber,
  });
})

export {
  simplifyData,
  trasformedByStepName,
  trasformedByStepNameAndAffector,
  transformByBatchNumber,
  transformByOwnerAlias,
}
